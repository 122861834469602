///
/// @file global/_mixins.scss
///
/// \brief Mixins are helpful snippets that let us not repeat ourselves
///
/// Mixins output css and style rules. Don't use a mixin where you could use
/// @extend instead.
///

// RTL support
@mixin swap_direction($type, $v) {
  @if length($v) == 4 {
    @if $text-direction == rtl {
      #{$type}: nth($v, 1) nth($v, 4) nth($v, 3) nth($v, 2);
    } @else {
      #{$type}: $v;
    }
  } @else {
    #{$type}: $v;
  }
}

// flip for RTL support
// if $rdirection==left (i.e. we're in RTL mode) => x *= -1
@function flipX($v, $rdirection) {
  @if $rdirection == left {
    @return -1 * $v;
  } @else {
    @return $v;
  }
}

///
/// Font Smoothing
/// Primarily used to improve rendering of light text on dark background
///
@mixin font-smoothing($value: antialiased) {
  @if $value == antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

///
/// Style input placeholders
///
@mixin input-placeholder {
  &.placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
    opacity: 1 !important;
  } //opacity needed to override FF user agent
  &::-moz-placeholder {
    @content;
    opacity: 1 !important;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
}

///
/// Style input placeholders
///
@mixin input-styled-check($icon, $suffix: 'checked', $padding: 10px) {
  // hide the actual element
  position: absolute;
  left: -9999px;
  ~ label,
  ~ .label {
    @include icon($icon);
    &::before {
      margin-right: $padding;
    }
  }
  &:checked {
    ~ label,
    ~ .label {
      @include icon($icon + '--' + $suffix);
    }
  }
}

///
/// Circles
///
/// Usage:
/// @include circle(200px, #999);
///
@mixin circle($default-circle-width: 41px, $color: #ccc) {
  width: $default-circle-width;
  height: $default-circle-width;
  background: $color;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

///
/// Triangles - creates right triangles with border-clipping tricks.
///
/// Adapted from this: http://codepen.io/vieron/pen/xuHDA
///
/// Usage:
/// @include triangle(size, color, direction)
/// @include bordered-triangle(size, color, direction, border-color, [border-width], [cut])
///
/// size: The length of the hypotenuse of the triangle
/// color: The fill color of the triangle
/// direction: Which way the arrow points
///
/// border-color: The color of the border around the triangle
/// border-width: The width of the border around the triangle
/// cut: Removes the border along the hypotenuse
///
@mixin triangle($size, $color, $direction) {
  height: 0;
  width: 0;
  border-color: transparent;
  border-style: solid;
  border-width: $size / 2;
  @if $direction == up {
    border-bottom-color: $color;
  } @else if $direction == right {
    border-left-color: $color;
  } @else if $direction == down {
    border-top-color: $color;
  } @else if $direction == left {
    border-right-color: $color;
  }
}

@mixin bordered-triangle($size, $color, $direction, $border-color, $border-width: 1px, $cut: true) {
  $back-size: $size + ($border-width * 2);
  position: relative;
  min-width: $size;
  min-height: $size;
  &:after,
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
  }
  &:after {
    @include triangle($size, $color, $direction);
  }
  &:before {
    @include triangle($back-size, $border-color, $direction);
    $mid-border-width: ($border-width / 2);
    $border-width-and-mid: $border-width + $mid-border-width;
    @if ($cut == true) {
      $mid-border-width: 0;
      $border-width-and-mid: $border-width * 2;
    }
    @if ($direction == up) or ($direction == down) {
      margin-left: -$border-width;
      @if ($direction == down) {
        margin-top: -$mid-border-width;
      } @else {
        margin-top: -$border-width-and-mid;
      }
    } @else if ($direction == right) or ($direction == left) {
      margin-top: -$border-width;
      @if ($direction == right) {
        margin-left: -$mid-border-width;
      } @else {
        margin-left: -$border-width-and-mid;
      }
    }
  }
}

///
/// Vertical centerer
/// Source: http://zerosixthree.se/vertical-align-anything-with-just-3-lines-of-css/
/// Note: This doesn't work in old browsers (ie. IE), you have to use javascript
/// for them if it's important.
///
@mixin vertical-center {
  @include transform(translateY(-50%));
  position: relative;
  top: 50%;
}

@mixin destroy-vertical-center {
  @include transform(none);
  position: static;
  top: auto;
}

///
/// Vertical & Horizontal centerer
///
@mixin vertical-horizontal-center {
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

%vertical-horizontal-center {
  @include vertical-horizontal-center;
}

@mixin font--heading {
  @include font-smoothing;
  font-family: $font--avenir-heavy;
  letter-spacing: -0.01em;
  font-weight: normal;
  line-height: 1.17;
}

///
/// Headlines
///
@mixin h1 {
  @include font--heading;
  font-size: 28px;
  @include breakpoint($medium-up) {
    font-size: 53px;
  }
}

@mixin h2 {
  @include font--heading;
  font-size: 28px;
  @include breakpoint($medium-up) {
    font-size: 41px;
  }
}

@mixin h3 {
  @include font--heading;
  font-size: 28px;
}

@mixin h4 {
  @include font--heading;
  font-size: 19px;
}

@mixin h5 {
  @include font--heading;
  font-size: 16px;
}

@mixin h6 {
  @include font--heading;
  font-size: 14px;
}

@mixin h7 {
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.2em;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  margin: 0 auto 30px auto;
  padding: 0 33px;
  &:before {
    content: '';
    background: $color-black;
    width: 100%;
    height: 2px;
    display: inline-block;
    position: absolute;
    top: 43%;
    left: 0;
  }
  .inner,
  &__inner {
    background: $color-white;
    display: inline-block;
    position: relative;
    padding: 0 7px 0 9px;
  }
}

///
/// Text
///
@mixin t1 {
  font-size: 16px;
  line-height: 1.375;
}

@mixin t2 {
  font-size: 14px;
  line-height: 1.28;
}

///
/// Headers
///

// All headers are the same size on mobile
@mixin header--mobile {
  @include font-smoothing;
  font-size: 19px;
  line-height: 1.31;
  letter-spacing: -0.01em;
}

// Extra Large Header A
@mixin header--extra-large {
  @include header--mobile;
  @include breakpoint($medium-up) {
    //font-family: $font--avenir-black;
    font-size: 53px;
    line-height: 1.32;
    letter-spacing: 0.14em;
  }
}

// Extra Large Header B
@mixin header--extra-large-alt {
  @include header--mobile;
  @include breakpoint($medium-up) {
    //font-family: $font--avenir-black;
    font-size: 53px;
    line-height: 1.22;
    letter-spacing: 0;
  }
}

// Large Header
@mixin header--large {
  @include header--mobile;
  @include breakpoint($medium-up) {
    //font-family: $font--avenir-heavy;
    font-size: 41px;
    line-height: 1.4;
    letter-spacing: -0.01em;
  }
}

// Medium Header
@mixin header--medium {
  @include header--mobile;
  @include breakpoint($medium-up) {
    //font-family: $font--avenir-medium;
    font-size: 28px;
    line-height: 1.25;
    letter-spacing: -0.01em;
  }
}

// Small Header
@mixin header--small {
  @include header--mobile;
  font-weight: normal;
}

@mixin content-container {
  width: 100%;
  max-width: $max-width;
  margin-#{$ldirection}: auto;
  margin-#{$rdirection}: auto;
}

@mixin responsive-container {
  @include content-container;
}

///
/// Fluid Images for responsive designs
///
@mixin fluid-image {
  display: block;
  height: auto;
  max-width: 100%;
  width: 100%;
}

@mixin button {
  @include font-smoothing;
  background: $color-black;
  color: $color-white;
  border: 2px solid $color-black;
  font-family: $font--avenir-heavy;
  font-size: 14px;
  padding: 12px 25px 11px 25px;
  text-transform: uppercase;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  transition: background $transition-duration $transition-effect;
  &:hover {
    background: $color-light-black;
    text-decoration: none;
    color: $color-white;
  }
  @include breakpoint($medium-up) {
    padding: 6px 25px 5px 25px;
    border-width: 1px;
  }
}

@mixin extended-button {
  @include button;
  &--light,
  &--inverted,
  &--quickshop,
  &--filter {
    background: $color-white;
    color: $color-black;
    transition: background $transition-duration $transition-effect;
    &:hover {
      background: $color-light-gray;
      color: $color-black;
    }
  }
  &--filter {
    font-family: $font--avenir-medium;
    font-size: 14px;
    text-transform: none;
    padding: 6px 12px 5px 12px;
    border-width: 1px;
    &.active {
      background: $color-black;
      color: $color-white;
    }
  }
  &--disabled {
    background: $color-gray;
    color: $color-white;
    border-color: $color-gray;
    cursor: not-allowed;
    &:hover {
      background: $color-gray;
      color: $color-white;
    }
  }
  &--quickshop {
    white-space: nowrap;
  }
}

@mixin button-large {
  padding: 12px 25px 11px 25px;
  width: 100%;
}

// Desktop large button
@mixin button-large--desktop {
  @include breakpoint($medium-up) {
    width: auto;
    padding: 10px 25px;
    min-width: 260px;
    font-size: 19px;
  }
}

///
/// Links
///
@mixin link {
  font-size: 16px;
  font-family: $font--avenir-heavy;
  color: $color-black;
  border-bottom: 2px solid $color-black;
  text-decoration: none;
  // Note: DO NOT explicity set uppercase for this selector
  //text-transform: uppercase;
  transition: background $transition-duration $transition-effect, border-width 0.1s linear;
  &:hover {
    color: $color-light-black;
    border-color: $color-light-black;
    border-width: 4px;
    text-decoration: none;
  }
}

@mixin extended-link {
  @include link;
  &--large {
    font-size: 19px;
  }
  &--inverted {
    @include font-smoothing;
    color: $color-white;
    border-color: $color-white;
    &:hover {
      color: $color-white;
    }
  }
}

// light version so we can use in styles only
@mixin button--light {
  background: $color-white;
  color: $color-black;
  border: 2px solid $color-white;
  &:hover {
    border-color: $color-black;
  }
}


// Body - Regular
@mixin body-text {
  font-family: $font--avenir-book;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 24px;
}
// Body - Bold
@mixin body-text--bold {
  font-family: $font--avenir-heavy;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
}

// Small Text - Regular
// Used in CTAs and products
@mixin body-text--small {
  font-family: $font--avenir-book;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.30000001192092896px;
  line-height: 24px;
}
// Small Text - Bold
@mixin body-text--small-bold {
  font-family: $font--avenir-heavy;
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.30000001192092896px;
  line-height: 24px;
}


// Title03
@mixin title-03 {
  font-family: $font--avenir-book;
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  letter-spacing: 0;
  @include breakpoint($large-up) {
    font-size: 24px;
    line-height: 28px;
  }
}

// Title03 - Bold
@mixin title-03--bold {
  font-family: $font--avenir-book;
  font-size: 20px;
  font-weight: bold;
  line-height: 20px;
  letter-spacing: 0;
  @include breakpoint($large-up) {
    font-size: 24px;
    line-height: 30px;
  }
}
