@import './../../../../scss/theme-bootstrap';

.waitlist-overlay {
  @include swap_direction(padding, 30px 20px 20px 20px);
  &-wrapper {
    &#colorbox {
      overflow: visible !important;
      #cboxLoadedContent,
      #cboxWrapper {
        // To display tooltip overlay over the overlay
        overflow: visible !important;
      }
    }
    &--space {
      #cboxLoadedContent {
        @include swap_direction(padding, 0);
      }
    }
  }
  .waitlist-form {
    &__container {
      min-height: 215px;
    }
    &__success {
      display: none;
    }
    &__form {
      text-align: center;
      input[type='checkbox'] ~ .label::before {
        float: none;
      }
      label {
        display: inline-block;
      }
      .tooltip {
        z-index: 2;
      }
    }
    &__cs-subheader {
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      @include breakpoint($medium-up) {
        @include swap_direction(margin, 0 auto 24px);
        width: 55%;
        line-height: 31px;
        font-size: 29px;
      }
      p {
        line-height: inherit;
      }
    }
    &__section {
      position: relative;
      @include breakpoint($medium-up) {
        @include swap_direction(margin, 0 auto);
        width: 83%;
      }
      .waitlist-form {
        &__email {
          color: $color-grey;
          width: 100%;
          height: 52px;
          text-align: center;
          padding-#{$rdirection}: 52px;
          letter-spacing: 0.2px;
          font-size: 19px;
        }
        &__submit {
          position: absolute;
          top: 0;
          right: 0;
          opacity: 0;
          z-index: 100;
        }
      }
      .icon--caret--right {
        position: absolute;
        top: 0;
        right: 0;
        width: 54px;
        height: 51px;
        color: $color-white;
        line-height: 53px;
        font-size: 27px;
        z-index: 1;
        background: $color-black;
      }
    }
    &__checkbox-text {
      @include swap_direction(margin, 7px auto 0);
      font-size: 13px;
    }
    &__errors {
      color: $color-red;
    }
  }
}
